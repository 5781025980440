<template>
  <b-modal
      :ok-title="isUpdateForm ? $i18n.t('admin.fields.editFieldsTemplate') : $i18n.t('admin.fields.addFieldsTemplate')"
      no-close-on-backdrop
      ok-only
      v-bind="$attrs"
      @close="customFieldData.name = fieldName"
      @ok="handleModalSubmit"
  >
    <template #modal-title>
      <h5
          v-if="isUpdateForm"
          class="mb-0"
      >
        {{ $i18n.t('admin.fields.editFieldsTemplate') }}
      </h5>
      <h5
          v-else
          class="mb-0"
      >
        {{ $i18n.t('admin.fields.addFieldsTemplate') }}
      </h5>
    </template>
    <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit }"
    >
      <!-- Form -->
      <form
          class="p-2"
          @submit.stop.prevent="handleSubmit(submitForm)"
      >
        <!-- Name -->
        <validation-provider
            #default="{errors}"
            name="name"
            rules="required"
        >
          <b-form-group
              :label="$i18n.t('Name')"
              label-for="name"
          >
            <b-form-input
                id="name"
                v-model="customFieldData.name"
                :state="errors.length > 0 ? false:null"
                autofocus
                placeholder="Name"
                trim
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <!-- type -->
        <validation-provider
            #default="{errors}"
            name="type"
            rules="required"
        >
          <b-form-group
              :label="$i18n.t('admin.fields.types.type')"
          >
            <v-select
                v-model="customFieldData.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="fieldTypes"
                :reduce="item => item.value"
                label="title"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <!--list item-->
        <validation-provider
            v-if="isList(customFieldData.type)"
            #default="{errors}"
            name="value"
            rules="required"
        >
          <b-form-group
              :label="$i18n.t('admin.fields.types.items')"
          >
            <b-form-tags
                v-model="customFieldData.value"
                :placeholder="$i18n.t('admin.fields.types.addNewItem')"
                class="mb-2"
                input-id="tags-basic"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <!--is Partner Name -->
        <validation-provider
            v-if="isAdmin()"
            #default="{errors}"
            name="isPartnerName"
            rules="required"
        >
          <b-form-group>
            <b-form-checkbox
                id="isPartnerName"
                v-model="customFieldData.isPartnerName"
                :state="errors.length > 0 ? false:null"
                inline
                name="isPartnerName"
                switch
            >
              {{ $i18n.t('admin.fields.isPartnerName?') }}
            </b-form-checkbox>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </form>
    </validation-observer>
  </b-modal>
</template>
<script>

import {required} from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {BFormTags} from 'bootstrap-vue'
import RepositoryFactory from "@/repositories/RepositoryFactory";
import {FieldsTypeEnum} from "@/enums/index";

export default {
  emits: ['refresh-data'],
  components: {
    BFormTags,
  },
  props: {
    isUpdateForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    oldCustomFieldData: {
      type: Object,
      required: false,
      default: null,
    }
  },
  data() {
    return {
      required,
      fieldName: '',
      customFieldData: {
        id: '',
        name: '',
        type: '',
        value: [],
        default: this.isAdmin(),
        isPartnerName: false,
      },
      defaultCustomFieldData: {
        id: '',
        name: '',
        type: '',
        value: [],
        default: this.isAdmin(),
        isPartnerName: false,
      },
      fieldTypes: [],
      repository: RepositoryFactory.create('fieldsTemplate'),
    }
  },
  watch: {
    customFieldData(val) {
      if (!this.isList(val.type)) {
        this.customFieldData.value = [];
      }
    },
    fieldTypes() {
      if (this.isUpdateForm) {
        this.getOldCustomFieldData();
      }
    },
    oldCustomFieldData() {
      this.getOldCustomFieldData()
    }
  },
  created() {
    this.getFieldTypes()
  },
  mounted() {
    this.resetForm()
  },
  methods: {
    addCustomField() {
      this.repository.store(this.formingPayload()).then(response => {
        this.successCallback(response)
      }).catch(error => {
        this.errorCallback(error)
      })
    },
    submitForm() {
      this.$refs.refFormObserver.validate().then(success => {
        if (!success) return;
        if (!this.isUpdateForm) {
          this.addCustomField()
        } else {
          this.updateCustomField()
        }
      });
    },
    handleModalSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.submitForm();
    },
    resetForm() {
      this.customFieldData = JSON.parse(JSON.stringify(this.defaultCustomFieldData));
    },
    successCallback(response) {
      this.$emit('refresh-data')
      this.resetForm()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$i18n.t('Success'),
          icon: 'EditIcon',
          variant: 'success',
          text: response.data.message
        },
      })
      this.$nextTick(() => {
        this.$bvModal.hide('customFieldsModal')
      });
    },
    errorCallback(error) {
      if (error.data && error.data.errors) {
        this.$refs.refFormObserver.setErrors(error.data.errors)
      }
    },
    getFieldTypes() {
      this.utilsAppRepository().getFieldTypes()
          .then(res => {
            this.fieldTypes = res.data.data
          })
    },
    updateCustomField() {
      this.repository.update(this.customFieldData.id, this.formingPayload()).then(response => {
        this.successCallback(response)
      }).catch(error => {
        this.errorCallback(error)
      })
    },
    formingPayload() {
      this.fieldName = this.customFieldData.name
      const payload = {
        name: this.customFieldData.name,
        type: this.customFieldData.type,
        value: this.customFieldData.value,
        default: this.customFieldData.default,
        isPartnerName: this.customFieldData.isPartnerName
      }
      if (this.isList(payload.type)) {
        this.$set(payload, 'value', JSON.stringify({...payload.value}));
      } else this.$set(payload, 'value', null);
      return payload
    },
    getTypeValues(values) {
      const typeValues = []
      values.forEach((item) => {
        typeValues.push(item);
      });
      return typeValues;
    },
    getOldCustomFieldData() {
      this.fieldName = this.oldCustomFieldData.name
      this.customFieldData = this.oldCustomFieldData
      if (this.oldCustomFieldData.value != null) {
        this.customFieldData.value = this.getTypeValues(Object.values(JSON.parse(this.oldCustomFieldData.value)))
      }
      this.customFieldData.isPartnerName = this.oldCustomFieldData.isPartnerName !== 0
    },
    isList(val) {
      return val === FieldsTypeEnum.LIST
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
