<template>
  <b-modal
      v-bind="$attrs"
      ok-only
      :ok-title="isUpdateForm ? $i18n.t('admin.category.editCategory') : $i18n.t('admin.category.addCategory')"
      @ok="handleModalSubmit"
  >
    <template #modal-title>
      <h5
          v-if="isUpdateForm"
          class="mb-0"
      >
        {{ $i18n.t('admin.category.editCategory') }}
      </h5>
      <h5
          v-else
          class="mb-0"
      >
        {{ $i18n.t('admin.category.addCategory') }}
      </h5>
    </template>
    <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
    >
      <!-- Form -->
      <form
          class="p-2"
          @submit.stop.prevent="handleSubmit(submitForm)"
      >

        <!-- Name -->
        <validation-provider
            #default="{errors}"
            name="name"
            rules="required"
        >
          <b-form-group
              :label="$i18n.t('Name')"
              label-for="name"
          >
            <b-form-input
                id="name"
                v-model="categoryData.name"
                autofocus
                :state="errors.length > 0 ? false:null"
                trim
                placeholder="Name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <!-- Color -->
        <colors :selected-color.sync="categoryData.color" />
      </form>
    </validation-observer>
  </b-modal>
</template>
<script>

import {required} from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import Colors from "@/components/common/colors.vue"

export default {
  emits: ['refresh-data'],
  components: {
    Colors,
  },
  props: {
    isUpdateForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    oldCategoryData: {
      type: Object,
      required: false,
      default: null,
    }
  },
  data(){
    return {
      required,
      categoryData: {
        id: '',
        name: '',
        color: "#90CDF4",
        default: this.isAdmin(),
        is_template: this.isAdmin(),
      },
      defaultCategoryData: {
        id: '',
        name: '',
        color: "#90CDF4",
        default: this.isAdmin(),
        is_template: this.isAdmin(),
      },
      changedValue: {},
      repository: RepositoryFactory.create('categories')
    }
  },
  watch: {
    oldCategoryData() {
      this.categoryData.id = this.oldCategoryData.id
      this.categoryData.name = this.oldCategoryData.name
      this.categoryData.color = this.oldCategoryData.color
      this.categoryData.default = this.oldCategoryData.default !== 0;
      this.categoryData.is_template = this.oldCategoryData.is_template !== 0;
    }
  },
  mounted() {
    this.resetForm()
  },
  methods: {
    addCategory(){
      this.repository.store(this.categoryData).then(response => {
        this.successCallback(response)
      }).catch(error => {
        this.errorCallback(error)
      })
    },
    submitForm(){
      this.$refs.refFormObserver.validate().then(success => {
        if (!success) return;
        if (!this.isUpdateForm) {
          this.addCategory()
        } else {
          this.updateCategory()
        }
      });
    },
    handleModalSubmit(bvModalEvent){
      bvModalEvent.preventDefault()
      this.submitForm();
    },
    resetForm() {
      this.categoryData = JSON.parse(JSON.stringify(this.defaultCategoryData));
    },
    successCallback(response) {
      this.$emit('refresh-data')
      this.resetForm()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$i18n.t('Success'),
          icon: 'EditIcon',
          variant: 'success',
          text: response.data.message
        },
      })
      this.$nextTick(() => {
        this.$bvModal.hide('categoryModal')
      });
    },
    errorCallback(error) {
      if (error.data && error.data.errors) {
        this.$refs.refFormObserver.setErrors(error.data.errors)
      }
    },
    updateCategory(){
      this.repository.update(this.categoryData.id, this.categoryData).then(response => {
        this.successCallback(response)
      }).catch(error => {
        this.errorCallback(error)
      })
    }
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
