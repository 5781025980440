var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',_vm._b({attrs:{"ok-title":_vm.isUpdateForm ? _vm.$i18n.t('admin.fields.editFieldsTemplate') : _vm.$i18n.t('admin.fields.addFieldsTemplate'),"no-close-on-backdrop":"","ok-only":""},on:{"close":function($event){_vm.customFieldData.name = _vm.fieldName},"ok":_vm.handleModalSubmit},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [(_vm.isUpdateForm)?_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$i18n.t('admin.fields.editFieldsTemplate'))+" ")]):_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$i18n.t('admin.fields.addFieldsTemplate'))+" ")])]},proxy:true}])},'b-modal',_vm.$attrs,false),[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"p-2",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Name'),"label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"autofocus":"","placeholder":"Name","trim":""},model:{value:(_vm.customFieldData.name),callback:function ($$v) {_vm.$set(_vm.customFieldData, "name", $$v)},expression:"customFieldData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$i18n.t('admin.fields.types.type')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.fieldTypes,"reduce":function (item) { return item.value; },"label":"title"},model:{value:(_vm.customFieldData.type),callback:function ($$v) {_vm.$set(_vm.customFieldData, "type", $$v)},expression:"customFieldData.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(_vm.isList(_vm.customFieldData.type))?_c('validation-provider',{attrs:{"name":"value","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$i18n.t('admin.fields.types.items')}},[_c('b-form-tags',{staticClass:"mb-2",attrs:{"placeholder":_vm.$i18n.t('admin.fields.types.addNewItem'),"input-id":"tags-basic"},model:{value:(_vm.customFieldData.value),callback:function ($$v) {_vm.$set(_vm.customFieldData, "value", $$v)},expression:"customFieldData.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),(_vm.isAdmin())?_c('validation-provider',{attrs:{"name":"isPartnerName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"isPartnerName","state":errors.length > 0 ? false:null,"inline":"","name":"isPartnerName","switch":""},model:{value:(_vm.customFieldData.isPartnerName),callback:function ($$v) {_vm.$set(_vm.customFieldData, "isPartnerName", $$v)},expression:"customFieldData.isPartnerName"}},[_vm._v(" "+_vm._s(_vm.$i18n.t('admin.fields.isPartnerName?'))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }