<template>
  <div class="manage-categories">
    <b-row>
      <b-col cols="3">
        <category-component
            v-if="isCanDo('indexCategories')"
            ref="categories-component"
        />
      </b-col>
      <b-col cols="6">
        <sections-component
            v-if="this.$store.getters['settings/getCurrentCategory']"
            @refresh-basic-sections="getBasicSectionsData"
        />
      </b-col>
      <b-col cols="3">
        <!-- size -->
        <div>
          <b-button-group
              v-if="isToggle"
              class="w-100 mb-1"
              size="sm"
          >
            <b-button
                variant="outline-primary"
                @click="show = 'fields'"
            >
              {{ $i18n.t('admin.fields.fieldsTemplate') }}
            </b-button>
            <b-button
                variant="outline-primary"
                @click="show = 'sections'"
            >
              {{ $i18n.t('admin.sections.basicSections') }}
            </b-button>
          </b-button-group>
          <div :class="isToggle ? 'sub-setting-card' : ''">
            <fields-template
                v-if="isCanDo('indexFieldTemplate')"
                v-show="!isToggle || show === 'fields'"
                ref="fields-template-component"
                :is-toggle="isToggle"
            />
            <basic-sections-component
                v-if="isCanDo('indexBasicSections')"
                v-show="!isToggle || show === 'sections'"
                ref="basic-sections-component"
                :is-toggle="isToggle"
                class="basicSectionClass"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import categoryComponent from '@/components/categories-management/categories/index.vue';
import basicSectionsComponent from '@/components/categories-management/basicSections/index.vue';
import sectionsComponent from '@/components/categories-management/sections/index.vue'
import fieldsTemplate from "@/components/categories-management/fieldsTemplate/index.vue";

export default {
  components: {
    categoryComponent,
    basicSectionsComponent,
    sectionsComponent,
    fieldsTemplate
  },
  data() {
    return {
      show: "fields",
    }
  },
  computed: {
    isToggle() {
      return this.isCanDo('indexFieldTemplate') && this.isCanDo('indexBasicSections');
    },
  },
  methods: {
    getBasicSectionsData() {
      if (this.isCanDo('indexBasicSections')) this.$refs["basic-sections-component"].refreshData()
    },
  }
}
</script>
<style scoped>
.manage-categories {
  margin-top: 30px;
}

.card {
  display: inline-block;
  margin: 20px;
}

.basicSectionClass {
  max-width: 300px;
}
</style>
<style src="@/assets/css/settings-categories.css"/>
