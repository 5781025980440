<template>
  <div>
    <div
        class="m-1"
    >
      <label>{{ $i18n.t('per_page') }}</label>
      <v-select
          v-model="perPage"
          :clearable="false"
          :options="perPageOptions"
          class="per-page-selector d-inline-block mx-50"
      />
    </div>
    <b-pagination
        v-model="page"
        :per-page="perPage"
        :total-rows="totalItems"
        align="default"
        class="p-1 mb-0"
        first-number
        last-number
        pills
        size="sm"
    />
  </div>
</template>

<script>
export default {
  props: {
    totalItems: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
    }
  },
  watch: {
    perPage() {
      this.refreshData()
    },
    page() {
      this.refreshData()
    },
  },
  methods: {
    refreshData() {
      this.$emit('refresh-data', true)
    },
    getParams() {
      return {
        page: this.page,
        perPage: this.perPage,
      }
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
