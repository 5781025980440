<template>
  <loading :show="is_loading">
    <div>
      <formSectionModel
          id="sectionModal"
          ref="formModal"
          :index-update-item="indexUpdateItem"
          :is-update-form.sync="isUpdateForm"
          :old-section-data="oldSectionData"
          @add-section="addSection"
          @update-section="updateSection"
      />
      <div class="setting-card-header">
        <h5>
          {{ $i18n.t('admin.sections.sections') }}
        </h5>
        <div>
          <b-button
              v-if="isHaveCurrentCategoryAnyUpdate"
              class="save"
              variant="primary"
              @click="saveSectionsChanges"
          >
            {{ $i18n.t('save') }}
          </b-button>
          <b-button
              v-if="accessTo('addSection')"
              class="add"
              variant="flat-primary"
              @click="activeAddModal"
          >
            <feather-icon
                icon="PlusIcon"
                size="21"
            />
          </b-button>
        </div>
      </div>
      <div class="setting-card">
        <draggable
            :group="accessTo('addSection') ? 'basicSections': ''"
            :list="newSections"
            class="dragArea list-group sections"
            handle=".handle"
            @change="change($event)"
        >
          <b-card
              v-for="(item,index) in newSections"
              :key="item.key"
              class="list-group-item"
              no-body
          >
            <div class="section-header">
              <div class="name">
                <feather-icon
                    v-if="accessTo('updateCategory')"
                    class="mr-1 handle"
                    icon="MenuIcon"
                    size="15"
                />
                <span class="text-bold">{{ item.name }}</span>
              </div>
              <b-dropdown
                  v-if="(accessTo('updateSection') || accessTo('deleteSection'))"
                  no-caret
                  variant="link"
              >
                <template #button-content>
                  <feather-icon
                      class="align-middle text-body"
                      icon="MoreVerticalIcon"
                      size="16"
                  />
                </template>
                <b-dropdown-item
                    v-if="accessTo('updateSection')"
                    @click="activeEditForm(item,index)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{ $i18n.t('Edit') }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                    v-if="accessTo('deleteSection')"
                    @click="confirmDelete(index)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $i18n.t('Delete') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <sections-fields
                :section="item"
                @update-fields="UpdateFields"
            />
          </b-card>
        </draggable>
      </div>
    </div>
  </loading>
</template>

<script>

import {BCard} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import formSectionModel from "./form.vue";
import sectionsFields from '../sectionsFields/index.vue'

export default {
  components: {
    formSectionModel,
    BCard,
    sectionsFields,
    draggable
  },
  data() {
    return {
      is_loading: false,
      isUpdateForm: false,
      oldSectionData: {},
      indexUpdateItem: 0,
      newSections: [],
    }
  },
  computed: {
    repository() {
      return RepositoryFactory.create('sections')
    },
    categoryRepository() {
      return RepositoryFactory.create('categories')
    },
    categoryId() {
      return this.$store.getters["settings/getCurrentCategory"].id
    },
    sectionsItems: {
      get() {
        return this.$store.getters["settings/getSectionsItems"];
      },
      set(value) {
        return this.$store.commit('settings/SET_SECTIONS_ITEMS', value);
      }
    },
    isHaveCurrentCategoryAnyUpdate() {
      const haveChange = !(JSON.stringify(this.newSections) === JSON.stringify(this.sectionsItems))
      this.$store.commit('settings/SET_HAVE_CHANGE', haveChange)
      return haveChange
    }
  },
  watch: {
    categoryId() {
      this.getData();
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.is_loading = true
      this.categoryRepository.getCategorySections(this.categoryId)
          .then(res => {
            this.$store.commit('settings/SET_SECTIONS_ITEMS_TO_EMPTY')
            const response = res.data
            response.data.forEach(item => {
              this.sectionsItems = item
            })
            this.newSections = JSON.parse(JSON.stringify(this.sectionsItems));
            this.is_loading = false
          })
    },
    refreshData() {
      this.getData()
    },
    refreshBasicSection() {
      this.$emit('refresh-basic-sections')
    },
    activeEditForm(item, index) {
      this.isUpdateForm = true
      this.oldSectionData = item
      this.indexUpdateItem = index
      this.$bvModal.show('sectionModal')
    },
    activeAddModal() {
      this.$refs.formModal.resetForm();
      this.isUpdateForm = false;
      this.$bvModal.show('sectionModal')
    },
    confirmDelete(sectionIndex) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.newSections.splice(sectionIndex, 1)
        }
      })
    },
    UpdateFields(sectionId, fields) {
      const index = this.newSections.findIndex(x => x.id === sectionId)
      this.newSections[index].fields = fields
    },
    checkDuplicateSection(section) {
      const items = this.newSections.filter(data => {
        return (data.name === section.name)
      })
      return items.length > 1
    },
    change(event) {
      if (event.added && this.checkDuplicateSection(event.added.element)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('Error'),
            icon: 'EditIcon',
            variant: 'danger',
            text: "you already have this section in this category"
          },
        })
        this.deleteSection(event.added.newIndex)
      } else if (event.added) {
        this.updateSection({
          id: event.added.element.name,
          name: event.added.element.name,
          default: 0,
          category_id: this.$store.getters['settings/categoryId'],
          position: event.added.newIndex,
          isBasicSection: false,
          fields: []
        }, event.added.newIndex)
      }
    },
    saveSectionsChanges() {
      let refreshBasicSection = false
      const updatedSections = []
      this.newSections.forEach((value, key) => {
        const item = value
        item.position = key
        item.isBasicSection = item.isBasicSection ?? false
        if (item.isBasicSection) refreshBasicSection = true
        updatedSections.push(item)
      })
      const category = {
        sections: updatedSections
      }
      this.is_loading = true
      RepositoryFactory.create('categories').updateSections(this.categoryId, category).then(response => {
        this.is_loading = false
        this.successCallback(response)
        if (refreshBasicSection) this.refreshBasicSection()
      }).catch(error => {
        this.is_loading = false
        this.errorCallback(error)
      })
    },
    successCallback(response) {
      this.refreshData()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$i18n.t('Success'),
          icon: 'EditIcon',
          variant: 'success',
          text: response.data.message
        },
      })
    },
    errorCallback(error) {
      if (error.data && error.data.errors) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: error.data.errors[0]
          },
        })
      }
    },
    deleteSection(index) {
      this.newSections.splice(index, 1)
    },
    addSection(data, index = null) {
      if (index != null) this.newSections.splice(index, 0, data)
      else this.newSections.push(data)
    },
    updateSection(data, index) {
      this.deleteSection(index)
      this.addSection(data, index)
    }
  }
}
</script>
