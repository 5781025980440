<template>
  <loading :show="is_loading">
    <div>
      <formCategoryModel
          id="categoryModal"
          ref="formModal"
          :is-update-form.sync="isUpdateForm"
          :old-category-data="oldCategoryData"
          @refresh-data="refreshData"
      />
      <div class="setting-card-header">
        <h5>
          {{ $i18n.t('admin.category.categories') }}
        </h5>
        <b-button
            v-if="isCanDo('addCategory')"
            class="add"
            variant="flat-primary"
            @click="activeAddModal"
        >
          <feather-icon
              icon="PlusIcon"
              size="21"
          />
        </b-button>
      </div>
      <b-card
          class="setting-card"
          no-body
      >
        <b-list-group
            class="v1"
            flush
        >
          <b-list-group-item
              v-for="item in items"
              :key="item.id"
              :active="item.id === $store.getters['settings/getCurrentCategory'].id"
              button
              @click="setActiveCategory(item)"
          >
            <div class="item-name">
              <div
                  :style="{backgroundColor: item.color}"
                  class="circle-color mr-1"
              />
              <span>{{ item.name }}</span>
            </div>
            <b-dropdown
                v-if="(canUpdate(item) || canDelete(item))"
                no-caret
                variant="link"
            >
              <template #button-content>
                <feather-icon
                    class="align-middle text-body menu"
                    icon="MoreVerticalIcon"
                    size="16"
                />
              </template>
              <b-dropdown-item
                  v-if="canUpdate(item)"
                  @click="activeEditForm(item)"
              >
                <feather-icon
                    icon="EditIcon"
                    size="12"
                />
                <span class="align-middle ml-50">{{ $i18n.t('Edit') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                  v-if="canDelete(item)"
                  @click="confirmDelete(item.id)"
              >
                <feather-icon
                    icon="TrashIcon"
                    size="12"
                />
                <span class="align-middle ml-50">{{ $i18n.t('Delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-list-group-item>
        </b-list-group>
        <template #footer />
        <pagination
            ref="pagination"
            :total-items="totalPages"
            @refresh-data="refreshData"
        />
      </b-card>
    </div>
  </loading>
</template>

<script>

import {BCard, BListGroup, BListGroupItem} from 'bootstrap-vue'
import RepositoryFactory from "@/repositories/RepositoryFactory";
import formCategoryModel from "./form.vue";
import Pagination from "@/components/settings/pagination.vue"

export default {
  components: {
    formCategoryModel,
    BCard,
    BListGroup,
    BListGroupItem,
    Pagination,
  },
  data() {
    return {
      totalPages: 1,
      items: [],
      is_loading: false,
      isUpdateForm: false,
      oldCategoryData: {},
    }
  },
  computed: {
    repository() {
      return RepositoryFactory.create('categories')
    }
  },
  created() {
    this.getData(false);
  },
  methods: {
    getData(isPaginate) {
      this.is_loading = true
      let paginate = {}
      if (this.$refs.pagination) paginate = this.$refs.pagination.getParams()
      else paginate = {page: 1, perPage: 10}
      this.repository.getAll(paginate)
          .then(res => {
            this.items = []
            const response = res.data
            response.data.forEach(item => {
              this.items.push(item)
            })
            this.totalPages = response.meta.total
            this.is_loading = false
            if (this.items.length && !isPaginate) this.$store.commit('settings/SET_CURRENT_CATEGORY', this.items[0])
          })
    },
    canUpdate(item) {
      return this.accessTo('updateCategory', item.permissions)
    },
    canDelete(item) {
      return this.accessTo('deleteCategory', item.permissions)
    },
    refreshData(value) {
      this.getData(value)
    },
    activeEditForm(item) {
      this.isUpdateForm = true
      this.oldCategoryData = item
      this.$bvModal.show('categoryModal')
    },
    activeAddModal() {
      this.$refs.formModal.resetForm();
      this.isUpdateForm = false;
      this.$bvModal.show('categoryModal')
    },
    confirmDelete(id) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        html: `${this.$i18n.t("You won't be able to revert this! <br>")} ${this.$i18n.t("<b style=\"color: red;\"> This category may has Sections all the category's sections will be deleted !!! </b>")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.repository.delete(id).then(response => {
            this.refreshData()
            this.$swal({
              icon: 'success',
              title: `${this.$i18n.t('Deleted')}!`,
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        }
      })
    },
    setActiveCategory(item) {
      if (this.$store.getters["settings/getHaveChange"]) {
        this.$swal({
          title: this.$i18n.t('Are you sure?'),
          html: `${this.$i18n.t("You won't be able to revert this! <br>")} ${this.$i18n.t("<b style=\"color: red;\"> You will lose your modifications to another category. !!! </b>")}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('Yes, I agree'),
          cancelButtonText: this.$i18n.t('Cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$store.commit('settings/SET_SECTIONS_ITEMS_TO_EMPTY')
            this.$store.commit('settings/SET_CURRENT_CATEGORY', item)
          }
        })
      } else this.$store.commit('settings/SET_CURRENT_CATEGORY', item)
    }
  }
}
</script>

<style scoped>
.circle-color {
  display: inline-block;
  height: 0.75rem;
  min-width: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
}
</style>
