<template>
  <div class="section-fields w-100">
    <draggable
        :draggable="false"
        :group="accessTo('addField') ? 'fieldsTemplate' : ''"
        :list="section.fields"
        :sort="false"
        class="dragArea list-group section-fields v1 w-100"
        @change="change($event)"
    >
      <div
          v-for="(field,index) in section.fields"
          :key="index"
          class="list-group-item w-50 mb-1"
      >
        <div class="field-name">
          <span class="name">{{ field.name }}</span>
          <small class="type text-muted">{{ getFieldTypeText(field.type) }}</small>
        </div>
        <div
            v-if="accessTo('deleteField')"
            class="action"
        >
          <span @click="deleteField(index)">
          <b-icon-x-circle class="delete-icon" />
         </span>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
import {BIconXCircle} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BIconXCircle,
    draggable
  },
  props: {
    section: {
      type: Object,
      required: true,
      default: null,
    },
  },
  methods: {
    checkDuplicateField(field) {
      const items = this.section.fields.filter(data => {
        return (data.name === field.name && data.type === field.type)
      })
      return items.length > 1
    },
    deleteField(index) {
      this.section.fields.splice(index, 1)
    },
    change(event) {
      if (event.added && this.checkDuplicateField(event.added.element)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('Error'),
            icon: 'EditIcon',
            variant: 'danger',
            text: "you already have this field in this section"
          },
        })
        this.deleteField(event.added.newIndex)
      }
      const updatedFields = []
      this.section.fields.forEach((value, key) => {
        const item = value
        item.position = key
        updatedFields.push(item)
      })
      this.$emit('update-fields', this.section.id, updatedFields)
      this.$forceUpdate()
    }
  }
}
</script>
