<template>
  <div>
    <b-modal
        :ok-title="isUpdateForm ? $i18n.t('admin.sections.edit') : $i18n.t('admin.sections.add')"
        ok-only
        v-bind="$attrs"
        @ok="handleModalSubmit"
    >
      <template #modal-title>
        <h5
            v-if="isUpdateForm"
            class="mb-0"
        >
          {{ $i18n.t('admin.sections.edit') }}
        </h5>
        <h5
            v-else
            class="mb-0"
        >
          {{ $i18n.t('admin.sections.add') }}
        </h5>
      </template>
      <validation-observer
          ref="refFormObserver"
          #default="{ handleSubmit }"
      >
        <!-- Form -->
        <form
            class="p-2"
            @submit.stop.prevent="handleSubmit(submitForm)"
        >

          <!-- Name -->
          <validation-provider
              #default="{errors}"
              name="name"
              rules="required"
          >
            <b-form-group
                :label="$i18n.t('Name')"
                label-for="name"
            >
              <b-form-input
                  id="name"
                  v-model="sectionData.name"
                  :state="errors.length > 0 ? false:null"
                  autofocus
                  placeholder="Name"
                  trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- checkbox -->
          <b-form-group v-if="!isUpdateForm && isAdmin()">
            <b-form-checkbox
                id="remember-me"
                v-model="isBasicSection"
                name="checkbox-1"
            >
              {{ $i18n.t('admin.sections.addToBasicSections?') }}
            </b-form-checkbox>
          </b-form-group>
        </form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>

import {required} from '@validations'

export default {
  emits: ['add-section', 'update-section'],
  props: {
    isUpdateForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    oldSectionData: {
      type: Object,
      required: false,
      default: null,
    },
    indexUpdateItem: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      required,
      sectionData: {},
      defaultSectionData: {},
      isBasicSection: false,
    }
  },
  watch: {
    oldSectionData() {
      this.sectionData = JSON.parse(JSON.stringify(this.oldSectionData))
    }
  },
  mounted() {
    this.resetForm()
  },
  methods: {
    handleModalSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.submitForm();
    },
    submitForm() {
      this.$refs.refFormObserver.validate().then(success => {
        if (!success) return;
        if (this.isUpdateForm) {
          this.updateSection()
        } else {
          this.addSection();
        }
      });
    },
    addSection() {
      this.sectionData.id = this.sectionData.name
      this.sectionData.category_id = this.$store.getters['settings/getCurrentCategory'].id
      this.sectionData.position = 0
      this.sectionData.isBasicSection = this.isBasicSection
      this.sectionData.fields = []
      this.$emit('add-section', this.sectionData)
      this.$bvModal.hide('sectionModal')
      this.resetForm()
    },
    updateSection() {
      this.$emit('update-section', this.sectionData, this.indexUpdateItem)
      this.$bvModal.hide('sectionModal')
      this.resetForm()
    },
    resetForm() {
      this.sectionData = JSON.parse(JSON.stringify(this.defaultSectionData));
      this.isBasicSection = false;
    },
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
