<template>
  <div>
    <formCustomFieldsModel
        id="customFieldsModal"
        ref="formModal"
        :is-update-form.sync="isUpdateForm"
        :old-custom-field-data="oldCustomFieldData"
        @refresh-data="refreshData"
    />
    <div
        v-if="!isToggle && isCanDo('addFieldTemplate')"
        class="setting-card-header"
    >
      <h5>
        {{ $i18n.t('admin.fields.fieldsTemplate') }}
      </h5>
      <b-button
          class="add"
          variant="flat-primary"
          @click="activeAddModal"
      >
        <feather-icon
            icon="PlusIcon"
            size="21"
        />
      </b-button>
    </div>
    <b-card
        class="setting-card"
        no-body
    >
      <b-button
          v-if="isToggle && isCanDo('addFieldTemplate')"
          variant="primary"
          @click="activeAddModal"
      >
        <feather-icon
            icon="PlusIcon"
            size="15"
        />
        {{ $i18n.t('admin.fields.addFieldsTemplate') }}
      </b-button>
      <b-list-group
          class="v1"
          flush
      >
        <draggable
            :disabled="!accessTo('addField')"
            :group="{ name: 'fieldsTemplate', pull: 'clone', put: false }"
            :list="items"
            :sort="false"
            class="dragArea list-group"
            handle=".handle"
        >
          <b-list-group-item
              v-for="item in items"
              :key="item.id"
              class="list-group-item"
          >
            <div class="item-name">
              <feather-icon
                  class="mr-1 handle"
                  icon="MenuIcon"
                  size="15"
              />
              <span>{{ item.name }}</span>
            </div>
            <b-dropdown
                v-if="(isCanDo('updateFieldTemplate') || isCanDo('deleteFieldTemplate')) && hasTeamAccess(item)"
                no-caret
                variant="link"
            >
              <template #button-content>
                <feather-icon
                    class="align-middle text-body"
                    icon="MoreVerticalIcon"
                    size="16"
                />
              </template>
              <b-dropdown-item
                  v-if="isCanDo('updateFieldTemplate')"
                  @click="activeEditForm(item)"
              >
                <feather-icon
                    icon="EditIcon"
                    size="12"
                />
                <span class="align-middle ml-50">{{ $i18n.t('Edit') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                  v-if="isCanDo('deleteFieldTemplate')"
                  @click="confirmDelete(item.id)"
              >
                <feather-icon
                    icon="TrashIcon"
                    size="12"
                />
                <span class="align-middle ml-50">{{ $i18n.t('Delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-list-group-item>
        </draggable>
      </b-list-group>
      <template #footer>
        <pagination
            ref="pagination"
            :total-items="totalItems"
            @refresh-data="refreshData"
        />
      </template>
    </b-card>
  </div>
</template>

<script>

import {BListGroup, BListGroupItem} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import RepositoryFactory from "@/repositories/RepositoryFactory";
import formCustomFieldsModel from './form.vue';
import Pagination from "@/components/settings/pagination.vue"

export default {
  components: {
    formCustomFieldsModel,
    BListGroup,
    BListGroupItem,
    draggable,
    Pagination
  },
  props: {
    isToggle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isUpdateForm: false,
      isModalSidebarActive: false,
      oldCustomFieldData: {},
      repository: RepositoryFactory.create('fieldsTemplate'),
    }
  },
  computed: {
    items() {
      return this.$store.getters["settings/fieldsTemplate"]
    },
    totalItems() {
      return this.$store.getters["settings/getFieldsTemplateTotalItems"]
    },
  },
  mounted() {
    this.refreshData()
  },
  methods: {
    refreshData() {
      this.$store.dispatch('settings/fetchFieldsTemplateFromApi', this.$refs.pagination.getParams())
    },
    activeEditForm(item) {
      this.isUpdateForm = true
      this.oldCustomFieldData = item
      this.$bvModal.show('customFieldsModal')
    },
    activeAddModal() {
      this.$refs.formModal.resetForm();
      this.isUpdateForm = false;
      this.$bvModal.show('customFieldsModal')
    },
    confirmDelete(item) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.repository.delete(item).then(response => {
            this.refreshData()
            this.$swal({
              icon: 'success',
              title: `${this.$i18n.t('Deleted')}!`,
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        }
      })
    },
    hasTeamAccess(team) {
      if (this.isAdmin()) return true
      return !team.default
    }
  }
}
</script>
